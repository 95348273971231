<!-- 采购订单记录-详情 -->
<template>
  <div class="pagePadding">
    <Table :productList="listColumns" :productData="listData">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ listData.length }}</span> 合计金额：<span class="color389">¥{{ totleMoney }}</span
        >
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/table'

export default {
  name: 'detailsPurchaseOrderRecordConsign',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach(item => {
        totle += item.money * item.num
      })
      return totle
    },
  },
  data() {
    return {
      // 表格数据
      listData: [
        {
          name: '药物涂层冠脉球囊导管',
          num: 2,
          money: 1000.0,
        },
        {
          name: '药物涂层冠脉球囊导管',
          num: 3,
          money: 1000.0,
        },
      ],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'version',
          align: 'center',
        },
        {
          title: '货号/SKU',
          key: 'SKU',
          align: 'center',
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
        },
        {
          title: '数量',
          key: 'num',
          align: 'center',
        },
        {
          title: '单价',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.price)])
          },
        },
        {
          title: '金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.money)])
          },
        },
        {
          title: '注册证号/备案凭证号',
          key: 'id',
          align: 'center',
        },
        {
          title: '供货单位',
          key: 'supply',
          align: 'center',
        },
        {
          title: '生产厂家',
          key: 'vender',
          align: 'center',
        },
        {
          title: '购货日期',
          key: 'data',
          align: 'center',
        },
        {
          title: '经手人',
          key: 'person',
          align: 'center',
        },
      ],
    }
  },
}
</script>

<style></style>
